<template>
  <footer class="footer">
    <div class="footer__page-links">
      <div
        class="footer__page-links__content footer__page-links__content--left"
      >
        <div class="footer__logo">
          <img :src="logoWhiteIcon" />
        </div>
        <div class="footer__page-links__content__nav-link-container">
          <router-link
            v-for="route in routerLinks"
            :key="route.id"
            :to="route.url"
            exact
            class="footer__page-links__nav-link"
            >{{ route.routeName }}</router-link
          >
        </div>
      </div>

      <div
        class="footer__page-links__content footer__page-links__content--right"
      >
        <a :href="telegramLink" target="_blank">
          <img class="footer__page-links__icon" :src="telegramIcon"
        /></a>
        <a :href="whatsappLink" target="_blank">
          <img class="footer__page-links__icon" :src="whatsappIcon"
        /></a>
      </div>
    </div>
    <hr />
    <div class="footer__social-media-links"></div>
  </footer>
</template>

<script>
export default {
  name: "my-footer",
  props: ["routerLinks"],
  data() {
    return {
      telegramIcon: require("@/assets/icons/telegram-icon.svg"),
      whatsappIcon: require("@/assets/icons/whatsapp-icon.svg"),
      logoWhiteIcon: require("@/assets/icons/tdg_name_white.png"),
    };
  },
  computed: {
    whatsappLink() {
      return `https://wa.me/6580134001?text=Hi!%20I%20would%20like%20to%20find%20out%20more%20information%20and%20schedule%20an%20appointment.`;
    },
    telegramLink() {
      return "https://t.me/thedefectguys";
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #1e1e1e;
  color: white;
  margin-top: 100px;
}
.footer__page-links {
  padding: 50px 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 100px;

  .footer__page-links__content {
    display: flex;
    &.footer__page-links__content--left {
      flex-direction: row;
    }
    &.footer__page-links__content--right {
      flex-direction: column;
    }
  }

  .footer__page-links__content__nav-link-container {
    display: flex;
    flex-direction: column;
    .footer__page-links__nav-link {
      text-decoration: none;
      color: white;
      margin-bottom: 15px;
    }
  }
}
.footer__social-media-links {
  height: 100px;
}
.footer__page-links__icon {
  width: 30px;
  margin-bottom: 15px;
}
.footer__logo {
  width: 150px;
  height: auto;
  margin-right: 50px;
  img {
    width: 100%;
    height: auto;
  }
}
hr {
  border: none;
  height: 1px;
  background-color: #757575;
  width: 100%;
}
</style>

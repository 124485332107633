<template>
  <section>
    <h1 class="text--left">Defect Specialist</h1>
    <p class="text--left">
      We are defect specialists and we are trained to detect them in your new
      home. Our inspectors have a keen eye for construction flaws, from minor
      imperfections like uneven flooring to more serious issues like water leaks
      or cracks in walls. We'll ensure your new home meets the highest standards
      so you can avoid costly repairs down the road.
    </p>
    <button><router-link to="/about">Our Story</router-link></button>
  </section>
</template>

<script>
export default {};
</script>

<style></style>

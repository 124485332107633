import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isWebView: false,
  },
  mutations: {
    SET_IS_WEB_VIEW(state, payload) {
      state.isWebView = payload;
    },
  },
  actions: {
    setIsWebView(context, payload) {
      context.commit("SET_IS_WEB_VIEW", payload);
    },
  },
  getters: {
    isWebView: (state) => {
      return state.isWebView;
    },
  },
  modules: {},
});

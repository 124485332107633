<template>
  <section>
    <h1 class="text--left">Why you need us</h1>
    <p class="text--left">
      The thrill of buying a new home can quickly turn into worry if defects go
      unnoticed. We have been in the construction industry, giving us an
      intimate understanding of common defects and potential problem areas.
    </p>
    <p class="text--left">
      Our inspectors are well-trained and have a hawk-eye for detail to inspect
      your new home. This isn’t just about checking boxes. It is a commitment to
      uncovering potential issues that the untrained eye might simply overlook.
      They understand the subtle signs that can indicate underlying problems,
      saving you a significant headache.
    </p>
  </section>
</template>

<script>
export default {};
</script>

<style></style>

<template>
  <section>
    <h1 class="text--left">Worried about defects?</h1>
    <p class="text--left">
      Moving into a new house should be exciting, not stressful. But
      construction defects lurk beneath the surface, and can be hard to detect
      with the untrained eye. Every house has a defect liability period (DLP),
      where your developers are liable to rectify them. In addition, this has to
      be done before any renovation work commences, and it is important to
      rectify them in this time frame.
    </p>
    <p class="text--left">
      That's where we come in. Our team of experienced defect specialists have a
      keen eye for catching them before they become problems. Don't let hidden
      issues turn your dream home into a nightmare. Contact us today for a
      comprehensive defect check!
    </p>
  </section>
</template>

<script>
export default {};
</script>

<style></style>

import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import MyHome from "../views/MyHome.vue";

Vue.use(Meta);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: MyHome,
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyAboutUs.vue"),
  },
  {
    path: "/services",
    name: "Services",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyServices.vue"),
  },
  {
    path: "/contact",
    name: "Contact Us",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyContactUs.vue"),
  },
  {
    path: "/inspections",
    name: "Inspection",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyInspection.vue"),
  },
  {
    path: "*",
    name: "Not Found",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

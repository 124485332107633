<template>
  <div id="app">
    <nav-bar :isWebView="isWebView" :routerLinks="routerLinks" />
    <router-view class="router-view" />
    <my-footer :routerLinks="routerLinks" />
  </div>
</template>

<script>
import MyFooter from "./components/MyFooter.vue";
import NavBar from "./components/nav/NavBar.vue";
export default {
  name: "App",
  metaInfo: {
    title: "The Defect Guys",
    titleTemplate: "%s | Defect Specialists",
  },
  components: { NavBar, MyFooter },
  data() {
    return {
      webViewMinThreshold: 480,
      isWebView: false,
      routerLinks: [
        { id: 1, url: "/", routeName: "Home" },
        { id: 2, url: "/about", routeName: "About Us" },
        { id: 3, url: "/services", routeName: "Services" },
        { id: 5, url: "/inspections", routeName: "Inspections" },
        { id: 4, url: "/contact", routeName: "Contact Us" },
      ],
    };
  },
  methods: {
    onResize() {
      this.isWebView = window.innerWidth > this.webViewMinThreshold;
      this.$store.dispatch(
        "setIsWebView",
        window.innerWidth > this.webViewMinThreshold
      );
    },
  },
  mounted() {
    this.isWebView = window.innerWidth > this.webViewMinThreshold;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  // created() {
  //   this.$store.subscribeAction({
  //     after: (action) => {
  //       if (action.type === "setIsWebView") {
  //         console.log(this.$store.state.isWebView);
  //       }
  //     },
  //   });
  // },
};
</script>

<style>
#app {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  font-size: 18px;
}
.router-view {
  margin-top: 62px;
}
</style>

<template>
  <section>
    <h2 class="text--left">Your Journey With us</h2>
    <div class="journey-step-container">
      <div v-for="step in journeyList" :key="step.id" class="stepper-item">
        <div>
          <div class="circle-number">
            <h2>{{ step.id }}</h2>
          </div>
        </div>
        <div class="step-content text--left">
          <h3 class="step-content__title">{{ step.title }}</h3>
          <p>{{ step.content }}</p>
        </div>
      </div>
    </div>
    <button v-if="isCTAButton">
      <router-link to="/services">Our Prices</router-link>
    </button>
  </section>
</template>

<script>
export default {
  name: "my-journey",
  props: {
    isCTAButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      journeyList: [
        {
          id: 1,
          title: "First Inspection",
          content:
            "Our inspectors conduct a thorough defect scan of your unit, providing a detailed explanation of their findings. Following this, a defects report based on the findings would be generated.",
        },
        {
          id: 2,
          title: "Joint Inspection",
          content:
            "A second trip carried out together with your developers prior to the rectification process, to ensure that the joint inspection goes smoothly and that the defects found are acknowledged.",
        },
        {
          id: 3,
          title: "Final Inspection",
          content:
            "A conclusive inspection will be performed to verify that all previously identified defects have been successfully rectified, ensuring a comprehensive and satisfactory resolution.",
        },
        {
          id: 4,
          title: "Post reno Inspection",
          content:
            "Following your renovation, our inspectors will conduct a meticulous defect inspection of your unit to identify any issues that have arisen during the renovation process.",
        },
        {
          id: 5,
          title: "Post reno Rectification",
          content:
            "After addressing the defects identified in the Post-Reno Inspection, our inspectors will perform a final check to confirm that all issues have been resolved satisfactorily, ensuring your renovated home meets our high standards.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.journey-step-container {
  width: 100%;
}
.stepper-item {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-top: 50px;
  position: relative;
}
.stepper-item:not(:last-child)::before {
  content: "";
  position: absolute;
  top: 54px;
  left: 25px; /* Adjust the position to center the line with the circle */
  width: 2px;
  height: Calc(100% - 2px);
  background-color: #666;
}

.circle-number {
  border-radius: 50%;
  border: 2px solid #666;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 20px;
  .step-content__title {
    margin: 0px;
  }
}
</style>

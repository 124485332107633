<template>
  <div class="home-section">
    <div class="home-banner-wrapper">
      <picture class="home-banner">
        <!-- For wide screens (2560px) -->
        <source
          :srcset="require(`@/assets/images/banner/home_banner_large.webp`)"
          media="(min-width: 1921px)"
          type="image/webp"
        />
        <source
          :srcset="require(`@/assets/images/banner/home_banner_large.jpg`)"
          media="(min-width: 1921px)"
          type="image/jpg"
        />

        <!-- For desktop (1920px) -->
        <source
          :srcset="require(`@/assets/images/banner/home_banner_web.webp`)"
          media="(min-width: 1025px)"
          type="image/webp"
        />
        <source
          :srcset="require(`@/assets/images/banner/home_banner_web.jpg`)"
          media="(min-width: 1025px)"
          type="image/jpg"
        />

        <!-- For tablets (1024px) -->
        <source
          :srcset="require(`@/assets/images/banner/home_banner_tablet.webp`)"
          media="(min-width: 481px)"
          type="image/webp"
        />
        <source
          :srcset="require(`@/assets/images/banner/home_banner_tablet.jpg`)"
          media="(min-width: 481px)"
          type="image/jpg"
        />

        <!-- For mobile (480px) -->
        <source
          :srcset="require(`@/assets/images/banner/home_banner_mobile.webp`)"
          media="(max-width: 480px)"
          type="image/webp"
        />

        <!-- Fallback for browsers that don't support <picture> -->
        <img
          :srcset="require(`@/assets/images/banner/home_banner_mobile.jpg`)"
          type="image/jpg"
          alt="Description of the image"
        />
      </picture>
      <div class="home-banner__slogan">
        <p class="home-banner__title rajdhani-bold">The Defect Guys</p>
        <p class="text--muted"><b>your defect specialists</b></p>
      </div>
    </div>
    <div class="section-wrapper">
      <defect-checker />
      <worried-c-t-a />
      <about-us-c-t-a />
      <my-journey :isCTAButton="true" />
    </div>
  </div>
</template>

<script>
import MyJourney from "../shared/MyJourney.vue";
import AboutUsCTA from "./AboutUsCTA.vue";
import WorriedCTA from "./WorriedCTA.vue";
import DefectChecker from "./DefectChecker.vue";
export default {
  components: {
    MyJourney,
    AboutUsCTA,
    WorriedCTA,
    DefectChecker,
  },
};
</script>
<style lang="scss" scoped>
.home-section {
  position: relative;
}
.home-banner {
  img {
    width: 100vw;
    height: Calc(80vh - 62px);
  }
}
.home-banner-wrapper {
  position: relative;
}
.home-banner__slogan {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0;
}
.home-banner__title {
  font-size: 48px;
  margin-bottom: 0;
}
</style>

<template>
  <home-section />
</template>

<script>
import HomeSection from "@/components/home/HomeSection.vue";

export default {
  name: "my-home-page",
  metaInfo: {
    title: "Home",
    meta: [
      {
        name: "description",
        content:
          "Expert defect specialists offering comprehensive inspections for new homes. Ensure the quality of your new home with our specialized defect inspection services.",
      },
    ],
  },
  components: { HomeSection },
};
</script>

<style></style>

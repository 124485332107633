<template>
  <div>
    <!-- web -->
    <nav class="nav-bar" v-if="isWebView">
      <router-link to="/" exact class="nav-bar__logo rajdhani-regular"
        ><img :src="navLogoIcon" />
        <p>The Defect Guys</p></router-link
      >
      <div class="nav-links-container">
        <router-link
          v-for="route in routerLinks"
          :key="route.id"
          :to="route.url"
          exact
          class="nav-link"
          >{{ route.routeName }}</router-link
        >
      </div>
    </nav>
    <!-- mobile -->
    <nav class="nav-bar nav-bar--mobile" v-else>
      <router-link to="/" exact> <div class="temp-logo" /></router-link>
      <div id="toggle-btn" @click="toggleNavMenu()">
        <div class="btn-burger"></div>
      </div>
      <div class="nav-menu" id="nav-menu">
        <div class="temp-logo" />
        <router-link
          v-for="route in routerLinks"
          :key="route.id"
          :to="route.url"
          exact
          class="nav-link"
          >{{ route.routeName }}</router-link
        >
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "nav-bar",
  props: ["isWebView", "routerLinks"],
  data() {
    return {
      navLogoIcon: require("@/assets/icons/tdg_white.png"),
    };
  },

  methods: {
    toggleNavMenu() {
      document.getElementById("toggle-btn").classList.toggle("active");
      document.getElementById("nav-menu").classList.toggle("active");
    },
  },
  watch: {
    $route: function () {
      if (!this.isWebView) {
        if (
          document.getElementById("toggle-btn").classList.contains("active")
        ) {
          document.getElementById("toggle-btn").classList.remove("active");
          document.getElementById("nav-menu").classList.remove("active");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar__logo {
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  text-decoration: none;
  img {
    height: 100%;
    width: auto;
    margin-right: 5px;
  }
}
.nav-bar {
  background-color: #1e1e1e;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100vw - 40px);
  z-index: 90;
  .nav-links-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.nav-link {
  color: rgb(107, 107, 107);
  text-decoration: none;
  margin: 0px 20px;
  transition: 300ms all ease-out;
  &::after {
    display: block;
    content: "";
    border-bottom: solid 1px white;
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
  }
  &.router-link-active {
    color: white;
  }
  &:hover {
    color: white;
    text-shadow: 0 0 0.9px white, 0 0 0.9px white, 0 0 0.9px white;

    &::after {
      transform: scaleX(1);
    }
  }
}
.nav-menu {
  background-color: #1e1e1e;
  height: calc(100vh - 200px);
  width: calc(100vw - 100px);
  padding: 100px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: -150vh;
  left: 0;
  position: fixed;
  z-index: 91;
  transition: all 300ms linear;
  &.active {
    top: 0px;
  }
  .temp-logo {
    margin-bottom: 100px;
  }
  .nav-link {
    margin: 20px 0px;
  }
}
#toggle-btn {
  height: 25px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 92;
  &.active {
    .btn-burger {
      transform: rotate(135deg);
    }
    .btn-burger::before,
    .btn-burger::after {
      top: 0;
      transform: rotate(90deg);
    }
  }
  .btn-burger {
    position: relative;
    max-width: 30px;
    max-height: 5px;
    min-width: 25px;
    min-height: 3px;
    width: 7vh;
    height: 2vh;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    transition: all 0.7s ease;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: -10px;
      max-width: inherit;
      max-height: inherit;
      min-width: inherit;
      min-height: inherit;
      width: inherit;
      height: inherit;
      border-radius: inherit;
      background: inherit;
    }
    &::after {
      top: 10px;
    }
  }
}
</style>
